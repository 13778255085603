<template>
    <div>
      <v-card>
        <v-card-title>
          <v-icon color="primary">mdi-food</v-icon>
          <span class="pl-2">Meal Menu</span>
          <v-spacer></v-spacer>
          <v-sheet elevation="3" rounded="lg" class="text-center mx-auto">
            <div class="mx-4">
              <v-radio-group v-model="mealType" row>
                <v-radio label="All Meals" value="all"></v-radio>
                <v-radio label="Available" value="available"></v-radio>
                <v-radio label="Unavailable" value="unavailable"></v-radio>
              </v-radio-group>
            </div>
          </v-sheet>
          <v-spacer></v-spacer>
	<create-button @create="createMeal"></create-button>  
      </v-card-title>
        <v-tabs v-model="activeTab" show-arrows class="my-2">
          <v-tab v-for="tab in tabs" :key="tab.idx">
            <v-icon size="20" class="me-3">{{ tab.icon }}</v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="activeTab">
          <v-tab-item>
            <meal-table :meals="filteredMeals" :loading="isLoading" @order-meal="orderMeal" @edit-meal="editMeal" @delete-meal="deleteMeal"></meal-table>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
  </template>
  
  <script>
  import MealTable from './meal-table.vue';
  import mealService from '@/services/MealService';
  import CreateButton from "@/components/CreateButton";  

  export default {
    components: {
      MealTable,
      CreateButton,
    },
    data() {
      return {
        mealType: 'all',
        activeTab: 0,
        tabs: [
          { idx: 0, icon: 'mdi-food', title: 'Meals' },
          // Add more tabs if needed
        ],
        meals: [],
        isLoading: false,
        error: null
      };
    },
    computed: {
      filteredMeals() {
        if (this.mealType === 'all') return this.meals;
        const isAvailable = this.mealType === 'available';
        return this.meals.filter(meal => meal.available === isAvailable);
      }
    },
    methods: {
      async fetchMeals() {
        this.isLoading = true;
        try {
          const response = await mealService.getMeals();
          this.meals = response.data;
        } catch (err) {
          this.error = 'Error fetching meals.';
        } finally {
          this.isLoading = false;
        }
      },
      createMeal() {
        // Navigate to a form page or show a modal for creating a meal
        this.$router.push({ name: 'createMeal' });
      },
      editMeal(meal) {
        // Navigate to a form page with the meal data
        this.$router.push({ name: 'editMeal', params: { id: meal.id } });
      },
      async deleteMeal(meal) {
        if (confirm('Are you sure you want to delete this meal?')) {
          try {
            await mealService.deleteMeal(meal.id);
            this.fetchMeals(); // Refresh the meal list
          } catch (err) {
            this.error = 'Error deleting meal.';
          }
        }
      },
      orderMeal(meal) {
        // Handle meal ordering
        this.$router.push({ name: 'order', params: { id: meal.id } });
      }
    },
    created() {
      this.fetchMeals();
    }
  };
  </script>
  
  
