<template>
    <v-data-table
      :headers="headers"
      :items="meals"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="$emit('edit-meal', item)">mdi-pencil</v-icon>
        <v-icon small class="mr-2" @click="$emit('delete-meal', item)">mdi-delete</v-icon>
        <!-- <v-btn color="primary" @click="$emit('order-meal', item)">
          Order
        </v-btn> -->
      </template>
    </v-data-table>
  </template>
  
  <script>
  export default {
    props: {
      meals: Array,
      loading: Boolean
    },
    data() {
      return {
        headers: [
          { text: 'Name', value: 'name' },
          { text: 'Description', value: 'description' },
          { text: 'Price', value: 'price' },
          { text: 'Available', value: 'available' },
          { text: 'Actions', value: 'actions', sortable: false }
        ]
      };
    },
    methods: {
      editMeal(meal) {
        // Navigate to a form page with the meal data
        this.$router.push({ name: 'editMeal', params: { id: meal.id } });
      },
      async deleteMeal(id) {
        if (confirm('Are you sure you want to delete this meal?')) {
          try {
            await mealService.deleteMeal(id);
            this.fetchMeals(); // Refresh the meal list
          } catch (err) {
            this.error = 'Error deleting meal.';
          }
        }
      },
    }
  };
  </script>
  
  <style scoped>
  /* Add your styles here */
  </style>
  
