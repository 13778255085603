import axios from 'axios';

import {Keys} from '/src/config.js';
const API_BASE_URL = Keys.API_BASE_URL + "/api";

export default {
  getMeals() {
    return axios.get(`/meals`);
  },
  getMeal(id) {
    return axios.get(`/meals/${id}`);
  },
  createMeal(mealData) {
    return axios.post(`/meals/create`, mealData);
  },
  updateMeal(id, mealData) {
    return axios.put(`/meals/${id}`, mealData);
  },
  deleteMeal(id) {
    return axios.delete(`/meals/${id}`);
  },
  placeOrder(orderData) {
    return axios.post(`${API_BASE_URL}/orders`, orderData);
  }
};
